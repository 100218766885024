import React, {Fragment} from 'react';

import Table from 'react-bootstrap/Table';
import schemactimage from './images/schemact.svg';
import entityImage from './images/schemactprocess/entity.svg'
import newImage from './images/schemactprocess/new.jpg'
import initialscreenImage from './images/schemactprocess/initialscreen.jpg'
import propertiesclickImage from './images/schemactprocess/propertiesclick.jpg'
import renameschema2Image from './images/schemactprocess/renameschema2.jpg'
import addentityImage from './images/schemactprocess/addentity.jpg'
import addentitiesImage from './images/schemactprocess/addentities.jpg'
import setupconnectionsImage from './images/schemactprocess/setupconnections.jpg'
import roleImage from './images/schemactprocess/role.svg'
import pathImage from './images/schemactprocess/path.svg'
import teamViewImage from './images/schemactprocess/teamview.jpg'
import teamAdminRoleImage from './images/schemactprocess/teamadminrole.jpg'
import completedSchemaImage from './images/schemactprocess/completedschema.jpg'
import instanceEditorLogoImage from './images/schemactprocess/instanceeditorlogo.svg'
import dataSchemaChoiceImage from './images/schemactprocess/dataschemachoice.jpg'
import teamViewDataImage from './images/schemactprocess/teamviewdata.jpg'
import teamViewInstanceImage from './images/schemactprocess/teamviewinstance.jpg'


function SchemactText() {
      const imageClassname = "screenshotimage"
      const curlyO = '{'
      const curlyC = '}'


      return (
        <Fragment> 
  A large part of enterprise development is mapping data schema to apis, server code, client code, datastores and security policies.
        The process is typically largely manual, expensive, the results are not open to change and fine grained security control is not an option.
        We just built a demo to explore an alternative process - we are calling it <strong>Schemact</strong> and it will evolve into a complete solution.
        <br/>
    Lets qualify some of the terms describing the ideal Schema service:
        <br/>
       <strong>Schema</strong> is meant in the sense of the data fields and structures required by the enterprise.
         This includes related default values and sample data. <strong>Schema</strong>s are defined by Developers / Architects / Analysts. 
        In our demo <strong>schemas</strong> are defined using a graphical editor located <a  target="_blank"
         href="https://schemactweb.testedsoftware.org/">here  <img width="20px" src={entityImage} alt="Schemact edit"/></a>
        <br/> 
      (Schema) <strong>complete</strong> is meant in the sense that, as far is possible, <strong>Schemact</strong> service automatically creates
         a complete set of artifacts based on the schemas defined.  These artifacts include, but are not limited to:
         
         <Table striped bordered hover>
    <tbody>
          <tr><td>Network Apis e.g. REST CRUD</td></tr>   
          <tr><td>Build Pipelines For Microservice Deployment to Serverless and or Kubernetes Hosts</td></tr>   
          <tr><td>SQL datastores e.g. MySQL database schemas and contained tables and indicies</td></tr>   
          <tr><td>No SQL datastores e.g. dynamo DB tables</td></tr>   
          <tr><td>Sample clients</td></tr>   
          <tr><td>Client Language Mappings</td></tr>   
          <tr><td>Cloud native fine grained security roles e.g. Roles mapped to AWS Cognito groups</td></tr>   
          <tr><td>Stress / performance tests</td></tr>   
          <tr><td>Performance Metrics</td></tr>   
  </tbody>
  </Table>
  <br/> 


  <br/>  Schema complete over <strong>time</strong> - over <strong>time</strong> is meant in the sense that schema changes over time are fully supported.
  The features required for this include but are not limited to:

<Table striped bordered hover>
<tbody>
  <tr><td>Schema version are first class citizens.
        Differences between versions are easy to view. Inter version compatibility is known.</td></tr> 
 <tr>
    <td>Multiple Versions are supported concurrently. This removes the pressure of upgrading all parts of a system at once.
       A client can continue to depend on a historic schema version while being served by a later schema version.
       A service can reflect a historic schema version whilst serving a later schema version.
         A native mobile app can continue working after the services it depends have upgraded</td>
 </tr> 
 <tr><td>Mapping between versions is automated as far as possible, code hooks are available where manual coding is required.</td></tr> 
<tr><td>Data store version upgrade is automated as far as possible.</td></tr> 
<tr><td>Best practice data store backup is automated as far as possible.</td></tr> 
</tbody>
</Table>
<br/> 

<br/> The schemact demo illustrates some of this process, here is a screen shotted example 
  -  in this we <strong>build and deploy a web api with fine grained security in 15 minutes</strong>  <br/> 

  <br/> logon <a  target="_blank" href="https://schemactweb.testedsoftware.org/">here <img width="20px" src={entityImage} alt="Schemact edit"/> </a>  <br/> 
  <br/> click on new icon  <br/> 

<img src={newImage} alt="new"  className ={imageClassname}/>

<br/>  This takes you into schema editor showing an empty schema - there are floating toolbar for schema properties
and for default imported schema <strong>simpleTypes</strong> and <strong>Generalx</strong> 
<img src={initialscreenImage} alt="new" className ={imageClassname}/>
<br/> 

<br/> click on the Properties button on the toolbar:  <br/> 

<img src={propertiesclickImage} alt="new" className ={imageClassname}/>

<br/> change the schema name and hit "Apply"<br/>

<img src={renameschema2Image} alt="new" className ={imageClassname}/>

<br/> drag the new entity tool icon onto the diagram to add a new Entity   <br/> 
<img src={addentityImage} alt="new" className ={imageClassname}/>

<br/> drag from the <strong>generalx</strong> toolbar to add views of Person and Address, Connect these to the team entity 
  with connect toolbar button   <br/> 
<img src={addentitiesImage} alt="new" className = {imageClassname}/>

<br/> double click the new entity, rename it to <strong>Team</strong>.  Add string properties <strong>name</strong>, <strong>altName</strong>.
  Rename the connection to <strong>Address</strong> as <strong>address</strong> and change it to 1 to 0 or 1 contained.
  Rename the connection to <strong>Person</strong> as <strong>members</strong> and change it to 1 to many contained.
  <br/> 
<img src={setupconnectionsImage} alt="new" className = {imageClassname}/>

<br/> At this point we have defined a schema - this is sufficient to generate datastores and a CRUD network API however we havent defined access.
Drag a new role <img width="20px" src={roleImage} alt="Role"/>,
 and a new path <img width="20px" src={pathImage} alt="Path"/> onto the diagram   <br/> 

 <br/> <img width="20px" src={pathImage} alt="Path"/> 
   Paths represent an allowed path through the data with per field security permissions.
    They can be mapped to SQL/GraphQL queries or updates/mutators overlayered with fine grained security.
  Double click the view, rename it, add explicit read write privilege for all the fields from root, save the view <strong>Team</strong> 
  <br/> 

    <img width="20px" src={teamViewImage} alt="teamView" className ={imageClassname}/> 

    <br/> Double click the role, rename it <strong>TeamAdmin</strong>, select the Team View and Save  <br/> 
   <img src={teamAdminRoleImage} alt="new" className ={imageClassname}/>
 
   <br/>  Now we can view our completed schema, note the editor automatically draws in the relationships from team view  <br/> 
   <img src={completedSchemaImage} alt="new" className ={imageClassname}/>

   <br/>  All we have to do now is code the server and client, provision the infrastructure and deploy it ?
  <strong>WRONG ! NO CODING IS REQUIRED - EVERYTHING IS ALREADY DEPLOYED</strong> - there is a universal test client to try out this new API
  <a  target="_blank" href="https://schemactwebdata.testedsoftware.org/">here <img width="20px" src={instanceEditorLogoImage} /> </a> 
  The default server runs on AWS API Gateway, Lambda and a serverless Aurora Cluster.
  More deployment options will be available in the future.
  At this point in time data will be saved to the default data store - a dynamo DB table.
   If you want to run arbitary queries (dont we all !), data can also be duplicated to a SQL database.
  This SQL database can be created in a few seconds by specifying a deployment object for the schema. This will be demonstrated in a future post.
  <br/>  If we log on the test client and click <strong>Load Schemas</strong> we get a list of all schemas we are authorised to access.
  For the demo system, authors get access to all the paths in all roles in all the schemas they created. 
   Other users only get access based on the roles they are attached to. Each role is automatically mapped to a Cognito Group.
    ( The authorisation process will be described in more a later post. )
    Here is the list of schemas in the default client - note some are in red, (including MySchema which we just created) 
    because they are subject to change and therefore dangerous, there is a process to create immutable versions called <strong>snapshotting </strong> which will be detailed in a future post:
    <br/> 

  <img src={dataSchemaChoiceImage} alt="new" className ={imageClassname}/>
  <br/>  Click <strong>MySchema</strong>, then select <strong>TeamView</strong> we get an edit for team view  <br/> 
<img src={teamViewDataImage} alt="teamviewdata" className ={imageClassname}/>
<br/>  Click <strong>Add</strong>, then select the row created.  This creates and saves a new Team based on the TeamView path. 
The save api is located here https://instanceapi.testedsoftware.org/save/{curlyO}schemaId{curlyC}/{curlyO}pathId{curlyC}/{curlyO}newInstanceId{curlyC} 
 and supports a fine grained event protocol based on the TeamView path we created earlier. This protocol will be described in a later post.
You can see the automatically created Team now,  its already been persisted to the default datastore, if you make changes they will also be stored:
<br/> 
<img src={teamViewInstanceImage} alt="teamviewdata" className ={imageClassname}/>
<br/> 
To recap <strong>we built and deployed a simple crud web api with fine grained security in 15 minutes</strong>. 
We have demonstrated some of Schemact notions of completeness.
<br/> 
<br/> 
 Future developments will provide performance metrics and address scalability issues and deal with concurrent schema versions.
 <br/> 
       
  </Fragment>
    );
}

export default SchemactText;