import React, {Fragment} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import schemactimage from './images/schemact.svg';
import entityImage from './images/schemactprocess/entity.svg'

import SchemactText from './SchemactText'

function Schemact() {
      return (
        <Fragment>      <Row  className="justify-content-md-center">
           <Col md="auto">
  <Card border="secondary">
  <Card.Header>Schemact</Card.Header>
    <Card.Img style={{ width: '18rem' }} variant="top" src={schemactimage} />
    <Card.Body>
      <Card.Title>Schemact -  Complete schema as a service over time, <small>Aug 2020</small> </Card.Title>
      <Card.Text>
        <SchemactText></SchemactText>
      </Card.Text>
    </Card.Body>
  </Card>
  </Col>
  </Row>
  </Fragment>
    );
}

export default Schemact;