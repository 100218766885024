import React, { FormEvent, Fragment } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FormProps } from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Alert } from 'react-bootstrap';
import axios from 'axios';

function ContactUs() {

  var theForm: any = null;

  const formElementValueByName = (form: HTMLFormElement, name: string) => {
    return (form.elements.namedItem(name) as unknown as { value: string }).value;
  }

  const onSubmit: React.FormEventHandler = function (event: FormEvent<HTMLFormElement>) {
    const form = event.currentTarget;
    const name = formElementValueByName(form, 'formBasicName');// as unknown as Record<string, Object>)['value']
    const email = formElementValueByName(form, 'formBasicEmail');//(elements.namedItem('formBasicEmail') as unknown as Record<string, Object>)['value']
    const comment = formElementValueByName(form, 'contractForm.comment');//(elements.namedItem('contractForm.comment') as unknown as Record<string, Object>)['value']
    console.log(`name= ${name} email=${email} comment=${comment}`);
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const url = 'https://0kc280lyzk.execute-api.eu-west-2.amazonaws.com/Prod/contactus';

    if (!name || name.length<3 ) {
         alert('Please specify a name');
    }
    else if ( !re.test(email) ) {
        alert('Please specify a valid email');
      }
    else if(!comment || comment.length<5) {
      alert('Please provide a comment');
    } else {
      axios.post(url, {name: name, email: email, desc: comment})
    }
    
    event.preventDefault();
  }

  theForm = (
    <Fragment>
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" placeholder="Enter email" />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" placeholder="Enter name" />
          <Form.Text className="text-muted">
            We'll never share your name with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="contractForm.comment">
          <Form.Label>Comment</Form.Label>
          <Form.Control as="textarea" rows={5} />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>



      <Row className="justify-content-md-center">
        <Col color="red" md="auto"><h4>Contact Us</h4></Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col color="red" md="auto"><h5>email: admin@testedsoftware.org</h5></Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col color="red" md="auto"><h4>Tested Software - Simplifying the Enterprise Lifecycle</h4></Col>
      </Row>
    </Fragment>
  );
  return theForm;
}

export default ContactUs;