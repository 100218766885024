import {Component} from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';


import logo from './logo.svg';
import Schemact from './Schemact';
import IoK from './IoK';
import ContactUs from './ContactUs';
import BlogSummaries from './BlogSummaries';
import {Page} from './Pages'



type AppState = {
  page: Page
};


class App extends Component<{}, AppState> {


  constructor(props: {}) {
    super(props)

  }

 defaultState() : AppState{
  return { page: Page.Summaries} 
 }

  createState(pageIn: Page) : AppState {
    return { page: pageIn }
}

state: AppState =  this.defaultState()

render() {

  const thus = this
  function clickHome() {
    const newState = thus.createState(Page.Summaries);
    console.log(`newState = ${newState.page}`)    
    return thus.setState(newState )     
  }
  function clickContactUs() {
    return thus.setState(thus.createState(Page.ContactUs) )     
  }

function isPage(page: Page) : boolean{
   return thus.state.page === page
}

function clickSummary(page: Page){
  console.log(`clicked summary ${page}`)
  return thus.setState(thus.createState(page) )     
}

  return (
   <Container fluid>
     <Row >
        <Col>
             <Navbar bg="light" expand="xl">
             <Navbar.Brand><strong> <img
        src={logo}
        width="30"
        height="30"
        className="d-inline-block align-top"
        alt="Testedsoftware logo"
        onClick={clickHome}
      /> Tested Software Limited</strong></Navbar.Brand>
     <Navbar.Brand href="#home"> </Navbar.Brand>
     <Navbar.Brand>Simplifying the Enterprise Lifecyle</Navbar.Brand>
     <Navbar.Toggle aria-controls="basic-navbar-nav" />
     <Navbar.Collapse id="basic-navbar-nav" >
       <Nav className="mr-auto">
         <Nav.Link  onClick={clickHome}  href="#home">Home</Nav.Link>
         <Nav.Link onClick={clickContactUs} href="#link">Contact</Nav.Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>
</Col>        
     </Row>
     {  (isPage(Page.Summaries)) ?  <BlogSummaries clickSummary={clickSummary} ></BlogSummaries> : "" }
     {  (isPage(Page.IoK)) ?  <IoK></IoK> : "" }
     {  (isPage(Page.Schemact)) ?  <Schemact  ></Schemact> : "" }
   {  (isPage(Page.ContactUs)) ?  (<ContactUs></ContactUs>) : ""} 

</Container>
  );
}
}

export default App;
