import React, {Fragment} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import cloudfrontimage from './images/cloudfrontcode.jpg';

import IoKText from './IoKText'

function IoK() {
      return (
        <Fragment>      <Row  className="justify-content-md-center">
           <Col md="auto">
  <Card border="secondary">
  <Card.Header>IoK</Card.Header>
    <Card.Img style={{ width: '18rem' }} variant="top" src={cloudfrontimage} />
    <Card.Body>
      <Card.Title>Infrastructure as <s>Code</s> Kotlin, <small>April 2020</small> </Card.Title>
      <Card.Text>
        <IoKText></IoKText>
      </Card.Text>
    </Card.Body>
  </Card>
  </Col>
  </Row>
  </Fragment>
    );
}

export default IoK;