import React, { Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import cloudfrontcode from './images/cloudfrontcode.jpg';
//import schemactimage from './images/schemactprocess/completedschema.jpg';
import schemactimage from './images/schemact.svg';
import cloudfrontimage from './images/cloudfrontcode.jpg';
import { Page } from './Pages';

interface BlogSummariesProps {
  clickSummary: (page: Page) => void
}

function BlogSummaries(props: BlogSummariesProps) {

  return (
      <Fragment>      <Row  className="justify-content-md-center">
         <Col md="auto">
<Card border="secondary">
<Card.Header>Schemact</Card.Header>
  <Card.Img style={{ width: '18rem' }} variant="top" src={schemactimage} />
  <Card.Body>
    <Card.Title>Schemact -  Complete schema as a service over time, <small>Aug 2020</small> </Card.Title>
    <Card.Text>
    A large part of enterprise development is mapping data schema to apis, server code, client code, datastores and security policies.
        The process is typically largely manual, expensive, the results are not open to change and fine grained security control is not an option.
        We just built a demo to explore an alternative process . . . .
    </Card.Text>
    <Button variant="primary" onClick={function() { props.clickSummary(Page.Schemact)}} >READ MORE »</Button>
  </Card.Body>
</Card>
</Col>
</Row>
      <Row  className="justify-content-md-center">
      <Col md="auto">
<Card border="info">
<Card.Header>IoK</Card.Header>
<Card.Img style={{ width: '18rem' }} variant="top" src={cloudfrontimage} />
<Card.Body>
 <Card.Title>Infrastructure as <s>Code</s> Kotlin, <small>April 2020</small></Card.Title>
 <Card.Text>
 Yaml isnt (real) code. Json isnt (real) code. Terraform script isnt (real) code. Ooops I said it.
        Luckily defining Infrastructure in Kotlin side steps the problems with these formats and enables the features needed to manage increasingly complex cloud systems.
 . . . .
 </Card.Text>
 <Button variant="primary"  onClick={function() { props.clickSummary(Page.IoK)}} >READ MORE »</Button>
</Card.Body>
</Card>
</Col>
</Row>
</Fragment>

    );
}

export default BlogSummaries;