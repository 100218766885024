import React, {Fragment} from 'react';
import Table from 'react-bootstrap/Table';

import Gist from 'react-gist';

function IoKText() {
  return (
    <Fragment> 
      <br/>Yaml isnt (real) code. Json isnt (real) code. Terraform script isnt (real) code. Ooops I said it.
        Luckily defining Infrastructure in Kotlin side steps the problems with these formats and enables the features needed to manage increasingly complex cloud systems.
        <br/>
        <br/> <strong>real</strong> is bit vague so instead here is a summary of language capabilities relating to cloud systems <br/>
 <br/>
        <Table striped bordered hover>

    <tbody>
          <tr ><th>Capability</th>
        <th>Kotlin</th>
        <th>Terraform / AWS Cloudformation</th>
        <th>Importance</th></tr>   
        <tr >
      <td>Automated Test For Resources</td>
      <td>Yes</td>
      <td>No</td>
      <td>Resource Stacks Have to be tested   </td>
    </tr>

    <tr>
      <td>Enforcement of Mandatory Resource Fields</td>
      <td>Explicit</td>
      <td>Run Time</td>
      <td>Increases productivity, reduces the chance of mistakes</td>
    </tr>
    <tr>
      <td>Functional Abstraction</td>
      <td>Yes</td>
      <td>To some extent</td>
      <td>Re-use and layering of common configurations to deal with increasing complexity </td>
    </tr>
    <tr >
      <td>Type safety</td>
      <td>Yes</td>
      <td>Run Time</td>
      <td>Increases productivity, reduces the chance of mistakes</td>
    </tr>
    <tr >
      <td>Server Code</td>
      <td>Yes</td>
      <td>No</td>
      <td>A full deployment requires server code - there is a dependency from infrastructure code to server code  </td>
    </tr>
    <tr >
      <td>Android Client Code</td>
      <td>Yes</td>
      <td>No</td>
      <td>Android Apps are often needed </td>
    </tr>
    <tr >
      <td>Typescript / Swift Client Code</td>
      <td>To some extent</td>
      <td>No</td>
      <td>Other types of client are often needed</td>
    </tr>
  </tbody>
  </Table>
<br/>

<br/> In summary Kotlin has better capabilites for defining infrastructure and additionally has better capabilites 
  for developing the code to deploy on that infrastructure  <strong>Kotlin is objectively the best choice for Cloud Infrastructure</strong> <br/>

  <br/>
  we have built a some maven hosted kotlin libraries for cloud infrastructure development:
 
  <Table striped bordered hover>

    <tbody>
    <tr>
      <td>  <a href="https://github.com/typedpath/cloudformation2kotlin">AWS cloudformation in Kotlin </a>
      </td>
    </tr>
    <tr >
      <td> <a href="https://github.com/typedpath/terraform2kotlin">Terraform for AWS in Kotlin </a>
      </td>
    </tr>
    <tr>
      <td>   <a href="https://github.com/typedpath/iam2kotlin">AWS IAM in Kotlin </a>
      </td>
    </tr>
</tbody>
  </Table>  

These libraries are scheduled:
<Table striped bordered hover>
<tbody>
    <tr>
    <td> Azure Resource Templates in Kotlin  </td>
  </tr>
  <tr>
    <td> Google Deployment Manager in Kotlin    </td>
  </tr>
  </tbody>
  </Table>  

<br/>

<br/><strong>Schemact</strong> is built on  <a href="https://github.com/typedpath/cloudformation2kotlin">AWS cloudformation in Kotlin </a>,
 its only at demo stage but would be unsustainable using terraform  or aws cloudformation 
<br/>


<br/>Here is a basic S3 example (full code here <a href="https://github.com/typedpath/cloudformation2kotlin/blob/master/cloudformation2kotlinsamples/tests/src/test/kotlin/com/typedpath/awscloudformation/test/s3/S3PublicReadableCloudFormationTemplate.kt">here </a>)

<br/>
xThis creates an s3 bucket and attaches a policy:
  <Gist id="typedpath/967f5b5ecc6eafc0343f904d8b1ce3dc" />
  <br/>


  The equivalent cloudformation yaml:
  <Gist id="typedpath/0ec658db6104b1f8a8e0e6719a7489c9"  />

  Some like-for like advantages the Kotlin template has over the cloudformation yaml template:
  
  <Table striped bordered hover>
<tbody>
      <tr ><td>Straightforward (compared to cloud formation Inputs) template parameterization e.g. <strong>bucketName: String</strong> argument</td></tr>
      <tr><td>Autocomplete on resource types and contained types e.g. <strong>AWS_S3_Bucket.WebsiteConfiguration</strong></td></tr>
      <tr ><td>Constants for IAM actions and resources e.g. <strong>S3Action.GetObject</strong> </td></tr>
      <tr><td>White space / alignment doesnt matter - the editor can correct</td></tr>
      <tr ><td><strong>ref</strong> method provides safe simple method of getting refs</td></tr>
      </tbody>
  </Table>  
<br/>

<br/>
We want a test, cloudformation or terraform wont give us that but Kotlin will,
 it can be colocated in the same repository and same java package.  The template <strong>bucketName</strong> parameter simplifies things.
 This test runs the template to create the S3 bucket, writes to the S3 bucket then reads from it. 

<Gist id="typedpath/fe2ee9f1f1da9f2d2d8f7f43e2903703" />

 <br/>

<br/>
  Even with this very simple example Kotlin has considerable advantages over yaml. 
  As infrastructure requirements become more complex the advantage is magnified.
  Following posts will provide some concrete examples. 
<br/>

  </Fragment>)
}

export default IoKText;